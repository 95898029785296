import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrl: "./error.component.scss",
})
export class ErrorComponent {
  public routeParams: Params;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.routeParams = this.activatedRoute.snapshot.queryParams;
  }

  protected reload(): void {
    this.router.navigateByUrl("/");
    window.location.reload();
  }
}
