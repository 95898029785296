import { Pipe, PipeTransform } from "@angular/core";
import { TextCaseTransform } from "../functions/text-case-transformations";

export enum TextCaseTransformations {
  KebabToCamel = "KebabToCamel",
  RegularToKebab = "RegularToKebab",
  RegularToCapitalized = "RegularToCapitalized",
  SnakeToCamel = "SnakeToCamel",
}

@Pipe({ name: "caseTransform" })
export class CaseTransformPipe implements PipeTransform {
  public transform(text: string, transformation: string): string {
    const transformations: string[] = Object.values(TextCaseTransformations);

    if (transformations.includes(transformation)) {
      switch (transformation) {
        case TextCaseTransformations.KebabToCamel:
          return TextCaseTransform.kebabToCamel(text);
        case TextCaseTransformations.RegularToKebab:
          return TextCaseTransform.regularToKebab(text);
        case TextCaseTransformations.RegularToCapitalized:
          return TextCaseTransform.regularToCapitalized(text);
        case TextCaseTransformations.SnakeToCamel:
          return TextCaseTransform.snakeToCamel(text);
      }
    }

    return text;
  }
}
