<div class="color-pick">
  <div class="color-pick__item-container" *ngFor="let color of defaultColors">
    <button
      *ngIf="!disabled"
      [style.background]="color"
      class="color-pick__item"
      [ngClass]="{
        'color-pick__item--active': color === selectedColor
      }"
      (click)="assignColor(color)"
    ></button>
    <button
      *ngIf="disabled"
      class="color-pick__item color-pick__item--disabled"
    ></button>
  </div>
  <span
    *ngIf="!isCustomColorSelected && !disabled"
    class="color-pick__item-container"
  >
    <span class="color-pick__item">
      <span (click)="colorPicker.click()" class="color-pick__item__more-icon"
        >+</span
      >
      <input
        class="color-pick__item__picker"
        (change)="colorSelected($event)"
        #colorPicker
        type="color"
      />
    </span>
  </span>
  <div
    *ngIf="isCustomColorSelected && !disabled"
    class="color-pick__item-container"
  >
    <div class="color-pick__selected-label">
      <button
        [style.background]="selectedColor"
        class="color-pick__item color-pick__item--active"
        (click)="assignColor(selectedColor)"
      ></button>
      <img
        class="color-pick__remove-icon"
        src="../../../../assets/images/as_delete_logo.svg"
        (click)="selectFirstDefaultColor()"
      />
    </div>
  </div>
</div>
