import { Component, Input, OnInit } from "@angular/core";
import { UnreachableCaseError } from "../../../../harmony/core";
import { CampaignPartnerStatus } from "../../enums/campaign.enums";
import { PartnerCampaign } from "../../models/partnerCampaign";
export interface PartnerStatus {
  readonly label: string;
  readonly iconClass: string;
  readonly iconName: string;
}

@Component({
  selector: "app-partner-campaign-status",
  templateUrl: "./partner-campaign-status.component.html",
  styleUrl: "./partner-campaign-status.component.scss",
})
export class PartnerCampaignStatusComponent implements OnInit {
  @Input({ required: true }) campaign!: PartnerCampaign;
  @Input() isHeader = false;
  protected partnerStatus?: PartnerStatus;
  private statuses: Record<string, PartnerStatus> = {
    waitForVerification: {
      label: "shared.campaignStatus.waitForVerification",
      iconClass: "wait-verification",
      iconName: "check_circle_outline",
    },
    accepted: {
      label: "shared.campaignStatus.waitForVerification",
      iconClass: "accepted",
      iconName: "check_circle_outline",
    },
    validated: {
      label: "shared.campaignStatus.validated",
      iconClass: "validated",
      iconName: "check_circle_outline",
    },
    youCanJoin: {
      label: "shared.campaignStatus.youCanJoin",
      iconClass: "validated",
      iconName: "check_circle_outline",
    },
    youHaveJoined: {
      label: "shared.campaignStatus.youHaveJoined",
      iconClass: "engaged",
      iconName: "check_circle_outline",
    },
    cancelled: {
      label: "shared.campaignStatus.cancelled",
      iconClass: "cancelled",
      iconName: "clear",
    },
    invited: {
      label: "shared.campaignStatus.invited",
      iconClass: "invited",
      iconName: "check_circle_outline",
    },
  };

  public ngOnInit(): void {
    switch (this.campaign.campaignPartnerStatus) {
      case CampaignPartnerStatus.PendingValidation:
        this.partnerStatus = this.statuses.waitForVerification;
        break;
      case CampaignPartnerStatus.Accepted:
        this.partnerStatus = this.statuses.accepted;
        break;
      case CampaignPartnerStatus.Validated:
        this.partnerStatus = this.statuses.youCanJoin;
        break;

      case CampaignPartnerStatus.Engaged:
        this.partnerStatus = this.statuses.youHaveJoined;
        break;

      case CampaignPartnerStatus.Cancelled:
        this.partnerStatus = this.statuses.cancelled;
        break;
      case CampaignPartnerStatus.Invited:
        this.partnerStatus = this.statuses.invited;
        break;
      case CampaignPartnerStatus.JoinLinkSent:
      case CampaignPartnerStatus.SendLinkToJoin:
      case CampaignPartnerStatus.WaitingToBeInvited:
        this.partnerStatus = undefined;
        break;
      default:
        throw new UnreachableCaseError(this.campaign.campaignPartnerStatus);
    }
  }
}
