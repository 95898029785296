import { Pipe, PipeTransform } from "@angular/core";

import { AppDataService } from "../services/api/app-data.service";

import { AppDataCountry } from "../models/appData";

@Pipe({
  name: "formatCountry",
  pure: false,
})
export class FormatCountryCodePipe implements PipeTransform {
  private countries: AppDataCountry[] = [];

  constructor(private readonly appDataService: AppDataService) {
    this.appDataService.get().then((appData) => {
      this.countries = appData.countries;
    });
  }

  public transform(value: string): string {
    if (!this.countries) {
      return "";
    }

    const country = this.countries.filter((c) => c.code === value);

    if (country.length > 0) {
      return country[0].name;
    }

    return value;
  }
}
