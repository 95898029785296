import {
  Component,
  ElementRef,
  Output,
  ViewChild,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  input,
} from "@angular/core";

@Component({
  selector: "app-infinite-scroll-anchor",
  templateUrl: "./infinite-scroll-anchor.component.html",
  styleUrl: "./infinite-scroll-anchor.component.scss",
})
export class InfiniteScrollAnchorComponent implements OnDestroy, AfterViewInit {
  public isLoading = input.required<boolean>();
  public loadingText = input<string | undefined>();

  @Output() public intersecting = new EventEmitter<void>();

  @ViewChild("loadAnchorElement")
  protected readonly loadAnchorElement?: ElementRef<HTMLDivElement>;

  private readonly observer$ = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && !this.isLoading()) {
      this.intersecting.emit();
    }
  });

  public ngAfterViewInit(): void {
    if (this.loadAnchorElement) {
      this.observer$.observe(this.loadAnchorElement.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this.observer$.disconnect();
  }
}
