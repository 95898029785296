<div class="row">
  <mat-form-field class="fieldset col width-75">
    <mat-label>{{
      "shippingAddress.addressPlaceholder" | translate
    }}</mat-label>
    <input
      matInput
      #addressShippingStreet="ngModel"
      [(ngModel)]="address.streetAndNumber"
      name="shippingAddressStreet"
      required
    />
    <mat-error>
      {{ "shippingAddress.addressRequired" | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="fieldset col width-25">
    <mat-label>{{ "shippingAddress.numberPlaceholder" | translate }}</mat-label>
    <input
      matInput
      #addressShippingNumber="ngModel"
      [(ngModel)]="address.streetAndNumber"
      name="shippingAddressNumber"
      required
    />
    <mat-error>
      {{ "shippingAddress.numberRequired" | translate }}
    </mat-error>
  </mat-form-field>
</div>
<div class="row">
  <mat-form-field class="fieldset col width-50">
    <mat-label>{{
      "shippingAddress.zipCodePlaceholder" | translate
    }}</mat-label>
    <input
      matInput
      #addressShippingPostalCode="ngModel"
      [(ngModel)]="address.postalCode"
      name="shippingAddressPostalCode"
      required
    />
    <mat-error>
      {{ "shippingAddress.zipCodeRequired" | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field class="fieldset col width-50">
    <mat-label>{{ "shippingAddress.cityPlaceholder" | translate }}</mat-label>
    <input
      matInput
      #addressShippingCity="ngModel"
      [(ngModel)]="address.city"
      name="shippingAddressCity"
      required
    />
    <mat-error>
      {{ "shippingAddress.cityRequired" | translate }}
    </mat-error>
  </mat-form-field>
</div>
<div class="row">
  <app-country-selector
    class="fieldset col width-50"
    [(value)]="address.country"
    name="shippingAddressCountry"
    placeholder="{{ 'shippingAddress.countryPlaceholder' | translate }}"
    required="true"
  />
</div>
<div class="row">
  <mat-form-field class="fieldset col width-100">
    <mat-label>{{
      "shippingAddress.additionalInfoPlaceholder" | translate
    }}</mat-label>
    <textarea
      matInput
      class="shipping-address-additional-information"
      #addressShippingAdditionalInformation="ngModel"
      [(ngModel)]="address.additionalInformation"
      name="shippingAddressAdditionalInformation"
      maxlength="255"
    ></textarea>
  </mat-form-field>
</div>
