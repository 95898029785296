import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "sort",
})
export class SortPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform<T extends Record<string, any>>(
    array: T[],
    property: string,
  ): T[] {
    return array.sort((a, b) => {
      const firstTerm = this.translateService.instant(a[property]);
      const secondTerm = this.translateService.instant(b[property]);

      return firstTerm && secondTerm ? firstTerm.localeCompare(secondTerm) : 0;
    });
  }
}
