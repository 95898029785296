import { Component, Input, booleanAttribute } from "@angular/core";

@Component({
  selector: "app-page-loader",
  templateUrl: "./page-loader.component.html",
  styleUrl: "./page-loader.component.scss",
})
export class PageLoaderComponent {
  @Input({ required: true }) public readonly text!: string;
  @Input({ transform: booleanAttribute }) public readonly transparent = false;
}
