import { ColorPalette } from "../../../features/landing-page/domain/models/color-palette";
import { Font } from "../../../features/landing-page/domain/models/font";

type CssRules = Record<string, string | undefined>;

export class LandingPageStylesBuilder {
  constructor(
    public readonly font: Font | undefined,
    public readonly colors?: ColorPalette | undefined,
  ) {}

  public get boldTextStyles(): CssRules {
    return {
      ...this.boldFont,
      color: this.colors?.textColor,
    };
  }

  public get boldFont(): CssRules {
    return {
      "font-family": this.font?.fontFamilyBold,
    };
  }

  public get regularTextStyles(): CssRules {
    return {
      ...this.regularFont,
      color: this.colors?.textColor,
    };
  }

  public get regularFont(): CssRules {
    return { "font-family": this.font?.fontFamily };
  }

  public get buttonStyle(): CssRules {
    return {
      "font-family": this.font?.buttonFontFamily,
      "background-color": this.colors?.buttonColor,
      color: this.colors?.textColor,
    };
  }

  public get backgroundStyles(): CssRules {
    return { "background-color": this.colors?.backgroundColor };
  }

  public get textColor(): string | undefined {
    return this.colors?.textColor;
  }
}
