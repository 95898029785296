import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "offsetToTimezone" })
export class OffsetToTimezonePipe implements PipeTransform {
  public transform(offset: number): string {
    const isNegative = offset > 0;
    const absoluteOffset = Math.abs(offset);
    const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, "0");
    const minutes = String(absoluteOffset % 60).padStart(2, "0");
    return `${isNegative ? "-" : "+"}${hours}:${minutes}`;
  }
}
