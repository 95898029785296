import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements OnChanges {
  @Input({ required: true }) public formControlInstance!: UntypedFormControl;
  @Input({ required: true }) public defaultColors!: string[];
  @Input() public disabled = false;

  @Output() public readonly colorChanged = new EventEmitter<string>();

  protected selectedColor?: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formControlInstance && !!this.formControlInstance) {
      this.selectedColor = this.formControlInstance.value;
    }
  }

  protected colorSelected(change: Event): void {
    const colorHex = (change.target as HTMLInputElement).value;
    this.assignColor(colorHex);
  }

  protected selectFirstDefaultColor(): void {
    this.assignColor(this.defaultColors[0]);
  }

  protected assignColor(color: string): void {
    if (!this.disabled) {
      this.selectedColor = color;
      this.formControlInstance.setValue(color);
      this.colorChanged.emit(color);
    }
  }

  protected get isCustomColorSelected(): boolean {
    return (
      !!this.selectedColor && !this.defaultColors.includes(this.selectedColor)
    );
  }
}
