import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import isEqual from "lodash/isEqual";
import { ColorPalette } from "../../../features/landing-page/domain/models/color-palette";
import {
  DEFAULT_FONTS,
  Font,
} from "../../../features/landing-page/domain/models/font";
import { LandingPageStylesBuilder } from "../../../landing-page/pages/landing-page-blocks/landing-page-styles-builder";
import { cloneWith } from "../../services/clonable";

@Component({
  selector: "app-font-picker",
  templateUrl: "./font-picker.component.html",
  styleUrls: ["./font-picker.component.scss"],
})
export class FontPickerComponent {
  @Input({ required: true }) public set colorPalette(colors: ColorPalette) {
    this.styles = cloneWith(this.styles, { colors: colors });
  }

  @Input({ required: true }) public set formControlInstance(
    formControl: FormControl<Font>,
  ) {
    this.formControl = formControl;
    this.styles = this.styles
      ? cloneWith(this.styles, { font: formControl.value })
      : new LandingPageStylesBuilder(formControl.value);
    this.setAvailableFonts(formControl.value);
  }

  @Output() public fontChanged = new EventEmitter<Font>();

  protected availableFonts: Font[] = DEFAULT_FONTS;
  protected selectedIndex = 0;
  protected styles!: LandingPageStylesBuilder;

  private formControl!: FormControl<Font>;

  public get formControlInstance(): FormControl<Font> {
    return this.formControl;
  }

  protected next(): void {
    this.selectedIndex = Math.min(
      this.selectedIndex + 1,
      this.availableFonts.length - 1,
    );
    this.selectFont(this.selectedIndex);
  }

  protected previous(): void {
    this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
    this.selectFont(this.selectedIndex);
  }

  private selectFont(index: number): void {
    const font = this.availableFonts[index];
    this.formControlInstance.setValue(font);
    this.styles = cloneWith(this.styles, { font: font });
    this.fontChanged.emit(font);
  }

  private setAvailableFonts(selectedFont: Font): void {
    this.selectedIndex = this.availableFonts.findIndex((font) =>
      isEqual(font, selectedFont),
    );
    if (this.selectedIndex === -1) {
      this.availableFonts = [selectedFont, ...this.availableFonts];
      this.selectedIndex = 0;
    }
  }
}
