<mat-form-field class="form-field">
  <mat-label>{{ placeholder }}</mat-label>
  <div class="input-wrapper">
    <input
      #input
      type="text"
      matInput
      name="{{ name }}"
      required="{{ isRequired() }}"
      [ngModel]="selectedValue"
      [matAutocomplete]="auto"
      (ngModelChange)="filterAndFillCountries($event)"
      (keydown.esc)="closeOptions()"
      (blur)="closeOptions()"
    />
    <div *ngIf="!filtering">
      <mat-icon class="select-arrow-icon">arrow_drop_down</mat-icon>
    </div>
    <div *ngIf="filtering">
      <mat-icon class="elect-search-icon">search</mat-icon>
    </div>
  </div>
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (opened)="openOptions()"
    (closed)="closeOptions()"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option
      *ngFor="let country of dropDownCountries"
      [class.mat-option--separator]="country.order === numFixedCountries - 1"
      [value]="country.code"
      (click)="selectCountry(country)"
    >
      <div class="option">
        <span class="option__text">{{ country.translatedName }}</span>
        <mat-icon
          *ngIf="isSelectedOption(country.code)"
          class="option__checked-icon"
          >check</mat-icon
        >
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
