<div class="not-found">
  <div class="not-found-action">
    <img height="200" src="/assets/images/reload-page-man.png" />
    <div class="not-found-action-text">
      {{ "shared.pageNotFound" | translate }}
    </div>
    <a href="app/static" class="button button-grey not-found-action-link">
      {{ "shared.continue" | translate }}
    </a>
  </div>
</div>
