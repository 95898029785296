<div
  *ngIf="partnerStatus"
  class="status"
  [ngClass]="{
    'status--header': isHeader
  }"
>
  <mat-icon class="{{ partnerStatus.iconClass }}">{{
    partnerStatus.iconName
  }}</mat-icon>
  <span>{{ partnerStatus.label | translate }}</span>
</div>
