<div class="error-overlay">
  <div class="error-overlay__background"></div>
  <div class="error-overlay__floating-content">
    <h2
      class="error-overlay__floating-content-title"
      *ngIf="routeParams?.error"
    >
      <span> {{ "errorDialog.unexpectedError" | translate }}</span>
      {{ routeParams?.error }}
    </h2>
    <a (click)="reload()" class="button button-orange">{{
      "errorDialog.goHome" | translate
    }}</a>
    <p>
      <span>{{ "errorDialog.subtitle" | translate }}</span>
      &nbsp;<a href="mailto:info@socialpals.de">info&#64;socialpals.de</a>
    </p>
  </div>
</div>
