import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[clickOutside]",
})
export class ClickOutsideDirective {
  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  @Output()
  public clickOutside = new EventEmitter<void>();

  @HostListener("document:touchstart", ["$event.target"])
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
