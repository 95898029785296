import { DatePipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ClickOutsideDirective } from "../directives/click-outside.directive";
import { CaseTransformPipe } from "./case-transform.pipe";
import { DayjsTransformPipe } from "./dayjs-transform.pipe";

import { FileSizePipe } from "./file-size.pipe";
import { FormatCountryCodePipe } from "./format-country-code.pipe";
import { FormatNumberPipe } from "./format-number.pipe";
import { KeysPipe } from "./keys.pipe";
import { LocalizeDatePipe } from "./localize-date.pipe";
import { OffsetToTimezonePipe } from "./offset-to-timezone.pipe";
import { SafeUrlPipe } from "./safe.pipe";
import { SortPipe } from "./sort.pipe";

const CUSTOM_PIPES = [
  CaseTransformPipe,
  ClickOutsideDirective,
  SafeUrlPipe,
  KeysPipe,
  FileSizePipe,
  FormatNumberPipe,
  FormatCountryCodePipe,
  OffsetToTimezonePipe,
  LocalizeDatePipe,
  SortPipe,
  DayjsTransformPipe,
];

@NgModule({
  declarations: [...CUSTOM_PIPES],
  exports: [...CUSTOM_PIPES],
  providers: [DecimalPipe, DatePipe],
})
export class SharedPipesModule {}
