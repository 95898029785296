export enum SortBy {
  Asc = 1,
  Desc = -1,
}

export class Sort {
  public static byText(
    a: string,
    b: string,
    order: SortBy.Asc | SortBy.Desc = SortBy.Asc,
  ): number {
    const normalizeString = (value: string): string =>
      value
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    return normalizeString(a) > normalizeString(b) ? order : -1 * order;
  }

  public static byNumber(
    a: number,
    b: number,
    order: SortBy.Asc | SortBy.Desc = SortBy.Asc,
  ): number {
    return a > b ? order : -1 * order;
  }
}
