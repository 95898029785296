import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNumber",
})
export class FormatNumberPipe implements PipeTransform {
  decimalSeparator = ","; // TODO: Get from user preferences
  thousandSeparator = "."; // TODO: Get from user preferences

  constructor(private readonly decimalPipe: DecimalPipe) {}

  public transform(value: any, currency: string): string {
    // Return original value if it's not a number, e.g. Yes/No
    if (isNaN(value) || value === null) {
      return value;
    }

    // SAFETY `!`: we know `formattedValue` is numeric (string or not) as it passed `isNaN` check.
    let formattedValue: string = value.toString();

    // Is currency, add 2 decimal places and append the passed currency symbol
    if (currency) {
      formattedValue = this.decimalPipe.transform(formattedValue, "1.2-2")!;
      formattedValue = formattedValue + currency; // Can be reformatted in the future to assign the symbol to left or right
    } else {
      // Add thousands separator
      formattedValue = this.decimalPipe.transform(formattedValue)!;
    }

    // By default, decimal separators are a period. If the user preference is a comma, we'll temporarily replace the separator with a text version of what we want to change it to.
    if (this.decimalSeparator === ",") {
      formattedValue = formattedValue.replace(".", "comma");
    }

    // By default, thousands separators are a comma. If the user preference is a period, we'll temporarily replace the separator with a text version of what we want to change it to.
    if (this.thousandSeparator === ".") {
      formattedValue = formattedValue.replace(",", "period");
    }

    // Format separators
    formattedValue = formattedValue.replace("period", ".");
    formattedValue = formattedValue.replace("comma", ",");

    return formattedValue;
  }
}
