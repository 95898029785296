<div class="font-picker">
  <div class="font-picker__row">
    <div class="font-picker__container">
      <button
        class="font-picker__arrow font-picker__arrow--left"
        [ngClass]="{
          'font-picker__arrow--disabled': selectedIndex === 0
        }"
        (click)="previous()"
      >
        <mat-icon class="font-picker__arrow__icon">chevron_left </mat-icon>
      </button>
      <div
        [ngStyle]="{ 'background-color': styles.colors?.backgroundColor }"
        class="font-picker__content"
      >
        <div class="font-picker__header">
          <div
            [ngStyle]="styles.boldTextStyles"
            class="font-picker__content__title"
          >
            {{ "fontPicker.yourTitle" | translate }}
          </div>
          <div
            [ngStyle]="styles.regularTextStyles"
            class="font-picker__content__subtitle"
          >
            {{ "fontPicker.sampleText" | translate }}
          </div>
        </div>
        <div class="font-picker__actions">
          <div class="font-picker__content__button-wrap">
            <div
              [ngStyle]="styles.buttonStyle"
              class="font-picker__button font-picker__button--{{
                formControlInstance?.value.name
                  | caseTransform: 'RegularToKebab'
              }}"
            >
              {{ "fontPicker.sampleButton" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="font-picker__footer">
        <div class="font-picker__footer__text">
          {{ formControlInstance?.value.name }}
        </div>
      </div>
      <button
        class="font-picker__arrow font-picker__arrow--right"
        [ngClass]="{
          'font-picker__arrow--disabled':
            selectedIndex === availableFonts.length - 1
        }"
        (click)="next()"
      >
        <mat-icon class="font-picker__arrow__icon">chevron_right </mat-icon>
      </button>
    </div>
  </div>
  <div class="font-picker__row">
    <div class="font-picker__dots">
      <ng-container *ngFor="let font of availableFonts; let i = index">
        <div
          [ngClass]="{
            'font-picker__dots__item--selected': i === selectedIndex
          }"
          class="font-picker__dots__item"
        ></div>
      </ng-container>
    </div>
  </div>
</div>
