import { Component, Input } from "@angular/core";
import { Address } from "../../models/address";

@Component({
  selector: "app-shipping-address",
  templateUrl: "./shipping-address.component.html",
  styleUrl: "./shipping-address.component.scss",
})
export class ShippingAddressComponent {
  @Input({ required: true }) address!: Address;
}
