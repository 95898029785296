import { Component, Input } from "@angular/core";

@Component({
  selector: "app-youtube-iframe",
  templateUrl: "./youtube-iframe.component.html",
  styleUrl: "./youtube-iframe.component.scss",
})
export class YoutubeIframeComponent {
  @Input({ required: true }) public readonly videoId!: string;
}
