import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxStripeModule } from "ngx-stripe";
import { NgxUploaderModule } from "@angular-ex/uploader";

import { environment as env } from "../../../environments/environment";
import { AngularMaterialModule } from "../material/material.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { AutosaveTooltipComponent } from "./autosave-tooltip/autosave-tooltip.component";
import {
  CarouselItemDirective,
  CarouselItemElementDirective,
} from "./carousel/carousel-item.directive";
import { CarouselComponent } from "./carousel/carousel.component";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { CountrySelectorComponent } from "./country-selector/country-selector.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { ErrorComponent } from "./error/error.component";
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";
import { FontPickerComponent } from "./font-picker/font-picker.component";
import { InfiniteScrollAnchorComponent } from "./infinite-scroll-anchor/infinite-scroll-anchor.component";
import { LoaderComponent } from "./loader/loader.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { PanoramicViewerComponent } from "./panoramic-viewer/panoramic-viewer.component";
import { PartnerCampaignStatusComponent } from "./partner-campaign-status/partner-campaign-status.component";
import { FacebookPostMockUpComponent } from "./post-mockups/facebook-post-mock-up/facebook-post-mock-up.component";
import { GenericPostMockUpComponent } from "./post-mockups/generic-post-mock-up/generic-post-mock-up.component";
import { GoogleAdMockUpComponent } from "./post-mockups/google-ad-mock-up/google-ad-mock-up.component";
import { InstagramPostMockUpComponent } from "./post-mockups/instagram-post-mock-up/instagram-post-mock-up.component";
import { LinkedInMultiImageMockUpComponent } from "./post-mockups/linkedin-post-mock-up/linkedin-multi-image-mock-up/linkedin-multi-image-mock-up.component";
import { LinkedInPostMockUpComponent } from "./post-mockups/linkedin-post-mock-up/linkedin-post-mock-up.component";
import { PostPreviewDialogComponent } from "./post-preview-dialog/post-preview-dialog.component";
import { ShippingAddressComponent } from "./shipping-address/shipping-address.component";
import { YoutubeIframeComponent } from "./youtube-iframe/youtube-iframe.component";

const SHARED_DIRECTIVES = [CarouselItemDirective];
const SHARED_COMPONENTS = [
  NotFoundComponent,
  ErrorComponent,
  ErrorDialogComponent,
  PageLoaderComponent,
  ShippingAddressComponent,
  AlertDialogComponent,
  ConfirmationDialogComponent,
  InfiniteScrollAnchorComponent,
  CountrySelectorComponent,
  AutosaveTooltipComponent,
  FacebookPostMockUpComponent,
  InstagramPostMockUpComponent,
  GoogleAdMockUpComponent,
  GenericPostMockUpComponent,
  LinkedInPostMockUpComponent,
  LinkedInMultiImageMockUpComponent,
  CarouselComponent,
  CarouselItemElementDirective,
  FileUploaderComponent,
  FontPickerComponent,
  ColorPickerComponent,
  YoutubeIframeComponent,
  PostPreviewDialogComponent,
  PartnerCampaignStatusComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ColorPickerModule,
    AngularMaterialModule,
    SharedPipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxStripeModule.forRoot(env.stripeApiKey),
    NgxUploaderModule,
    LoaderComponent,
    PanoramicViewerComponent,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  exports: [TranslateModule, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
})
export class SharedComponentsModule {}
