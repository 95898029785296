import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Subscription } from "rxjs";
import { Sort } from "../../functions/sort-by";

import { AppDataCountry } from "../../models/appData";
import { AppDataService } from "../../services/api/app-data.service";
import { LanguageService } from "../../services/language.service";
import { assert } from "../../utils/assert";

@Component({
  selector: "app-country-selector",
  templateUrl: "./country-selector.component.html",
  styleUrls: ["./country-selector.component.scss"],
})
export class CountrySelectorComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public placeholder!: string;
  @Input({ required: true }) public name!: string;
  @Input({ required: true }) public value!: string;
  @Input() public required = false;

  @Output() public valueChange = new EventEmitter<string>();

  @ViewChild("input", { static: true })
  public input!: ElementRef<HTMLInputElement>;

  public selectedValue?: string;
  public filtering = false;
  public dropDownCountries: AppDataCountry[] = [];
  public numFixedCountries!: number;

  private previousValue?: string;
  private translatedCountries: AppDataCountry[] = [];
  private subscriptions = new Subscription();

  constructor(
    public appDataService: AppDataService,
    private languageService: LanguageService,
  ) {}

  public ngOnInit(): void {
    this.selectedValue = this.value;
    this.loadCountries();

    this.subscriptions.add(
      this.languageService.changed$.subscribe(() => {
        this.loadCountries();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private loadCountries(): void {
    this.appDataService.get().then((appData) => {
      this.translateCountriesList(appData.countries);
      this.filterAndFillCountries();
    });
  }

  private translateCountriesList(countries: AppDataCountry[]): void {
    this.translatedCountries = this.languageService.getTranslatedCountries(
      countries,
      this.languageService.locale,
    );
  }

  public filterAndFillCountries(filteredValue?: string): void {
    this.numFixedCountries = this.translatedCountries.filter(
      (c) => c.order !== undefined,
    ).length;

    this.dropDownCountries = this.translatedCountries
      .filter((country) =>
        filteredValue
          ? (country.translatedName ?? "")
              .toLowerCase()
              .includes(filteredValue.toLowerCase())
          : true,
      )
      .sort((a, b) =>
        Sort.byText(a.translatedName ?? "", b.translatedName ?? ""),
      )
      .sort(
        (a, b) =>
          (a.order ?? this.numFixedCountries) -
          (b.order ?? this.numFixedCountries),
      );
  }

  public displayFn(value: string): string {
    return value
      ? this.translatedCountries.find((country) => country.code === value)
          ?.name ?? ""
      : "";
  }

  public selectCountry(country: AppDataCountry): void {
    this.value = country.code;
    this.previousValue = country.code;
    this.valueChange.emit(this.value);
  }

  public openOptions(): void {
    if (!this.filtering) {
      this.previousValue = this.selectedValue;
      this.selectedValue = undefined;
      this.filterAndFillCountries();
    }

    this.filtering = true;
  }

  public closeOptions(): void {
    this.filtering = false;
    this.selectedValue = this.previousValue;
    setTimeout(() => {
      this.filterAndFillCountries();
    }, 250);
  }

  public optionSelected(option: MatAutocompleteSelectedEvent): void {
    const correspondingCountry = this.translatedCountries.find(
      (country) =>
        country.code.toLowerCase() === option.option.value.toLowerCase(),
    );

    assert(correspondingCountry, "Country not found");

    this.selectCountry(correspondingCountry);
    this.input?.nativeElement.blur();
  }

  public isSelectedOption(countryCode: string): boolean {
    return countryCode === this.value;
  }

  public isRequired(): boolean {
    if (this.filtering) {
      return false;
    }
    return this.required;
  }
}
