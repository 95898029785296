export enum HorizontalAlignment {
  Left = "left",
  Center = "center",
  Right = "right",
}

export enum VerticalAlignment {
  Top = "top",
  Center = "center",
  Bottom = "bottom",
}

export class Font {
  constructor(
    public name: string,
    public fontFamily: string,
    public fontFamilyBold: string,
    public buttonFontFamily: string,
  ) {}
}

export enum FontType {
  Roboto = "Roboto",
  RobotoCondensed = "Roboto Condensed",
  RobotoSlab = "Roboto Slab",
  Merriweather = "Merriweather",
  Montserrat = "Montserrat",
}

enum AvailableFonts {
  Merriweather = "Merriweather",
  MontserratBold = "Montserrat Bold",
  Roboto = "Roboto",
  RobotoBlack = "Roboto Black",
  RobotoBold = "Roboto Bold",
  RobotoCondensedBold = "Roboto Condensed Bold",
  RobotoSlabBold = "Roboto Slab Bold",
}

export const DEFAULT_FONTS = [
  new Font(
    FontType.Roboto,
    AvailableFonts.Roboto,
    AvailableFonts.RobotoBold,
    AvailableFonts.RobotoBlack,
  ),
  new Font(
    FontType.RobotoCondensed,
    AvailableFonts.Roboto,
    AvailableFonts.RobotoCondensedBold,
    AvailableFonts.RobotoBlack,
  ),
  new Font(
    FontType.RobotoSlab,
    AvailableFonts.Roboto,
    AvailableFonts.RobotoSlabBold,
    AvailableFonts.RobotoBlack,
  ),
  new Font(
    FontType.Merriweather,
    AvailableFonts.Roboto,
    AvailableFonts.Merriweather,
    AvailableFonts.RobotoSlabBold,
  ),
  new Font(
    FontType.Montserrat,
    AvailableFonts.Roboto,
    AvailableFonts.MontserratBold,
    AvailableFonts.RobotoBold,
  ),
];
